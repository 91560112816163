import {NgModule} from '@angular/core';
import {RouterModule, ROUTES, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalService,
} from '@azure/msal-angular';
import {AuthService} from './services/auth.service';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {DynamicPageComponent} from './components/pages/dynamic-page/dynamic-page.component';
import {JsonPipe} from '@angular/common';
import {HomeComponent} from './components/pages/home/home.component';
import {BlankComponent} from './components/shared/blank/blank.component';
import {LaneSelectionComponent} from './components/pages/lane-selection/lane-selection.component';
import {DamageDetailComponent} from './components/shared/template/damage-detail/damage-detail.component';
import {ProblemDetailComponent} from './components/shared/template/problem-detail/problem-detail.component';
import {GeneralComponent} from './components/shared/template/general/general.component';
import {DocumentsComponent} from './components/pages/documents/documents.component';
import {ScorecardComponent} from "./components/shared/template/scorecard/scorecard.component";
import {ScorecardDetailComponent} from "./components/shared/template/scorecard-detail/scorecard-detail.component";
import {UserSettingsComponent} from "./components/shared/user-settings/user-settings.component";
import { PncComponent } from './components/pages/pnc/pnc.component';
import {BadRequestComponent} from "./components/pages/bad-request/bad-request.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'page',
    children: [
      {
        path: 'primary',
        children: [
          {
            path: 'scorecards/lane-selection',
            component: LaneSelectionComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/detail',
            component: ScorecardDetailComponent,
            canActivate: [MsalGuard],
            children: [

            ]
          },
          {
            path: 'scorecards/problem',
            component: ProblemDetailComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/problem/pnc',
            component: PncComponent,
            canActivate: [MsalGuard],

          },
          {
            path: '**',
            component: ScorecardComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: 'secondary',
        children: [
          {
            path: 'scorecards/lane-selection',
            component: LaneSelectionComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/detail',
            component: ScorecardDetailComponent,
            canActivate: [MsalGuard],
            children: [

            ]
          },
          {
            path: 'scorecards/problem',
            component: ProblemDetailComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/problem/pnc',
            component: PncComponent,
            canActivate: [MsalGuard],

          },
          {
            path: '**',
            component: ScorecardComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: 'warehousing',
        children: [
          {
            path: 'scorecards/lane-selection',
            component: LaneSelectionComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/detail',
            component: ScorecardDetailComponent,
            canActivate: [MsalGuard],
            children: [

            ]
          },
          {
            path: 'scorecards/problem',
            component: ProblemDetailComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'scorecards/problem/pnc',
            component: PncComponent,
            canActivate: [MsalGuard],

          },
          {
            path: '**',
            component: ScorecardComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: 'masterdata',
        children: [
          {
            path: '**',
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: 'documents',
        children: [
          {
            path: 'other',
            component: DocumentsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: '**',
            component: GeneralComponent,
            canActivate: [MsalGuard],
          },
        ],
        canActivate: [MsalGuard],
      },
    ],
    canActivate: [MsalGuard],
  },
  {
    path: 'blank',
    component: BlankComponent,
  },
  {
    path: 'user',
    component: UserSettingsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '400',
    component: BadRequestComponent,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    MsalService,
    MsalBroadcastService,
    {
      provide: ROUTES,
      useFactory: () => {
        return [...routes];
      },
      multi: true,
      deps: [AuthService, MsalService, MsalBroadcastService],
    },
  ],
})
export class AppRoutingModule {
}
