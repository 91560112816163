import { Component, Inject, Input, OnInit } from '@angular/core';

import { TableControllerService } from '../../../../services/table-controller.service';
import {Pnc, PncSerial, ProblemDetail} from '../../../../models/response';
import {
  Data,
  DataSet,
  Layout,
  SectionLayout,
} from '../../../../models/layout';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LayoutServiceService } from '../../../../services/layout.service';
import { GeneralService } from '../../../../services/crud/general.service';
import { map, timeout } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { DialogComponent } from '../../dialog/dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SpinnerService } from '../../../../services/spinner.service';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { ALERT_TYPE } from '../../alert/alert.enumerate';
import { AlertService } from '../../alert/alert.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { cloneDeep, isEqual, isNil, toNumber } from "lodash";
import { CameraRollComponent } from '../../camera-roll/camera-roll.component';
import { QrCodeComponent } from '../../qr-code/qr-code.component';
import { SerialComponent } from 'src/app/components/pages/serial/serial.component';
import {SerialSelectionComponent} from "../../../pages/serial-selection/serial-selection.component";
import {BackDialogComponent} from "../../dialog/back-dialog/back-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import { ScorecardComponent } from '../scorecard/scorecard.component';
import { PncComponent } from 'src/app/components/pages/pnc/pnc.component';
import { CameraRollV2Component } from '../../camera-roll-v2/camera-roll-v2.component';

function convertFormat(detailElement: any, columnName: string): string {
  if (columnName == 'INSERT_DATE' || columnName == 'UPDATE_DATE') {
    if (isNil(detailElement)) {
      const newDate = new Date();
      return (1 + newDate.getMonth()).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0') + '-' + newDate.getFullYear();
    }

    let newDetail = cloneDeep(detailElement);
    const newStringDate = newDetail.replace(/\ \ /gm, ' ').replace(/\//gm, ' ')
    const newStringArray = newStringDate.split(' ');

    if (newStringArray.length == 4 ) {
      return newStringArray[1].padStart(2, '0') + "-" + newStringArray[0].padStart(2, '0') + "-" + newStringArray[2] + " " + newStringArray[3];
    } else if (newStringArray.length == 3) {
      return newStringArray[1].padStart(2, '0') + "-" + newStringArray[0].padStart(2, '0') + "-" + newStringArray[2];
    } else {
      return '';
    }
  }
  return detailElement;
}



@Component({
  selector: 'app-problem-detail',
  templateUrl: './problem-detail.component.html',
  styleUrls: ['./problem-detail.component.scss'],
  providers: [
    {
      provide: 'PncTableController',
      useClass: TableControllerService,
    },
    {
      provide: 'SerialTableController',
      useClass: TableControllerService,
    },
  ],
})
export class ProblemDetailComponent implements OnInit {
  @Input()
  layout: Layout | undefined;

  detail: ProblemDetail = {
    CATEGORY_ID: '',
    L_SCORECARD_PROBLEM_PNC: [],
    PROBLEM_APPLIANCES_QTY: 0,
    PROBLEM_PACKAGES_QTY: 0,
    REASON_D_ID: '',
    REASON_ID: '',
    SCORECARD_ID: '',
    SCORECARD_PROBLEM_ID: '',
    CAMERA_ROLL_ID:'',
    files: []
  };
  detailBck: ProblemDetail | undefined;

  isEditable: boolean = false;

  isNew: boolean = false;
  titlePage: string = '';
  pageId: string = '';
  problemId: string = '';
  scr: string = '';
  dmgType: String = '';
  apiParent: string = 'primary';
  camera: string = '';
  isavailable: boolean=false;
  shipment: string = '';

  apiPath: string = '/scorecards/problem/detail';

  //apiUrl: string = 'primary/problems/detail';

  disabledColums: string[] = ['PNC_APPLIANCES_QTY', 'PNC_PACKAGES_QTY'];

  defaultCheckedButton: string[] = ['IS_PACKAGE'];

  disabledActions = [
    { id: 'ACT_ADD', motivation: 'Save problem before adding PNC' }
  ]

  isChanged: boolean = false;

  pageData: Map<string, Data> = new Map<string, Data>();



  constructor(
    @Inject('PncTableController')
    public pncTableController: TableControllerService,
    @Inject('SerialTableController')
    public serialTableController: TableControllerService = new TableControllerService(),
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public layoutService: LayoutServiceService,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    public dialog: MatDialog,
    private _location: Location,
    public alertService: AlertService,
    public navigation: NavigationService,
    public dialogRef: MatDialogRef<ProblemDetailComponent>,
    private translateService: TranslateService,


    //@Inject(MAT_DIALOG_DATA) public data: any
  ) {


    pncTableController.customizeAfterAddClick = (newRowObj: any): any => {

      newRowObj['PNC_PACKAGES_QTY'] = 0;
      newRowObj['PNC_APPLIANCES_QTY'] = 0;

      if (!!this.pncTableController.dataSet && this.pncTableController.dataSet.length > 0) {
        (this.pncTableController.dataSet[this.pncTableController.rowNumber])['L_SCORECARD_PROBLEM_PNC_SERIAL'] = cloneDeep(this.serialTableController.dataSet.map(serial => {
          delete serial.edited;
          delete serial.editing;
          delete serial.newRow;
          return serial;
        }) ?? []);

        this.recalculateDetail();
        this.serialTableController.dataSet = []
      }

      if (newRowObj['L_SCORECARD_PROBLEM_PNC_SERIAL']) {
        return cloneDeep(newRowObj);
      } else {
        newRowObj['L_SCORECARD_PROBLEM_PNC_SERIAL'] = [];
        return cloneDeep(newRowObj);
      }
    }


    this.activatedRoute.queryParams.subscribe(params => {

      this.pageId = params['id'] ?? '';
      this.problemId = params['problemId'] ?? '';

      this.isEditable = (params['dmgStatus'] ?? 'CANCELLED') != 'CANCELLED';
      this.scr = params['scr'] ?? '';
      this.dmgType = params['dmgType']
      let isNewStr = params['n'] ?? 'false';
      this.isNew = (isNewStr == 'true')
      this.shipment = params['shipment']

      //this.detail = JSON.parse(params['detail']);
      //this.detailBck = cloneDeep(params['detail']);

      //this.pncTableController = !!this.detail ? this.detail['L_SCORECARD_PROBLEM_PNC'] : [];
    });


    this.pncTableController.dataSet = this.detail?.L_SCORECARD_PROBLEM_PNC ?? [];
    this.pncTableController.selection?.clear();

    if ((this.detail?.L_SCORECARD_PROBLEM_PNC ?? []).length > 0) {
      this.pncTableController.row = this.detail?.L_SCORECARD_PROBLEM_PNC[0];
      this.pncTableController.rowNumber = 0;
    }


    this.serialTableController.dataSet =
      this.pncTableController.dataSet[0]?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? [];
    this.serialTableController.selection.clear();

    const layoutsString = localStorage.getItem('layout');
    if (!!layoutsString) {
      const layouts = JSON.parse(layoutsString);
      if (!!layouts && !!layouts[this.pageId]) {
        const pageLayout = layouts[this.pageId];
        this.layout = pageLayout['instance'];
        if (!!this.layout && this.isNew) this.catchAllSelectedOptions(this.layout);
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          if (!!this.layout && this.isNew) this.catchAllSelectedOptions(this.layout);
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
             this.pageActionset = this.pageLayouts[0].actionset ?? [];
             this.pageDataset = this.pageLayouts[0].dataset ?? [];
             ////(this.pageActionset)
           }*/
        });
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          if (!!this.layout && this.isNew) this.catchAllSelectedOptions(this.layout);
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
             this.pageActionset = this.pageLayouts[0].actionset ?? [];
             this.pageDataset = this.pageLayouts[0].dataset ?? [];
             ////(this.pageActionset)
           }*/
        });
      }
    } else {
      this.spinner.show();
      this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
        this.layout = res?.layouts[0] ?? undefined;
        const layoutsString = localStorage.getItem('layout');
        if (!!layoutsString) {
          let layouts = JSON.parse(layoutsString);
          layouts[this.pageId] = {
            instance: res?.layouts[0],
            instant: new Date(),
          };
          localStorage.setItem('layout', JSON.stringify(layouts));
        } else {
          let layouts = {};
          layouts[this.pageId] = {
            instance: res?.layouts[0],
            instant: new Date(),
          };
          localStorage.setItem('layout', JSON.stringify(layouts));
        }
        ////(this.layout)
        this.spinner.hide();
        if (!!this.layout && this.isNew) this.catchAllSelectedOptions(this.layout);
        /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
           this.pageActionset = this.pageLayouts[0].actionset ?? [];
           this.pageDataset = this.pageLayouts[0].dataset ?? [];
           ////(this.pageActionset)
         }*/
      });
    }
  }

  private catchAllSelectedOptions(layout: Layout) {
    const dtSet: Set<Data> = new Set(layout.sectionLayouts.flatMap((sl) => sl.dataset).flatMap((dts) => (dts.datas ?? [] as Data[])));



    this.pageData = Array.from(cloneDeep(dtSet)).reduce((hMap, data) => hMap.set(data.columnName, data), new Map());

    this.pageData.forEach(data => {

      if (data.columnName == 'CATEGORY_ID' && data.valuesFrom.length > 0) {
        let reserchString = cloneDeep(data.valuesFrom)
        const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
          const subStringContent = substr.replace('_@', '').replace('@_', '');
          if(this.detail){
            return {
              toReplace: substr,
              raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
            }
          }
          return {
            toReplace: substr,
            raplaceWith: ''
          }
        }) ?? [];

        for(let i=0; i< replaceArray.length; i++){
          reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
        }

        this.generalDataService
          .getSelectionOptionWithFilter(
            reserchString,
            "CATEGORY_WORKFLOW = '" + this.dmgType + "'"
          )
          .then((res) => {
            data.optionValues = res;
            if(this.detail && data.optionValues.length > 0){
              const categoryId = ((res.find((elem)=> elem.default) ?? (res.length > 0 ? (res[0]) : undefined)))?.id;
              if( !(!!this.detail.CATEGORY_ID)){
                this.detail.CATEGORY_ID = categoryId ?? '';

                if(!!categoryId){
                  this.onColumnChange(categoryId, 'CATEGORY_ID');
                }
              }else{
                this.onColumnChange(categoryId, 'CATEGORY_ID');
              }
            }
          });
      } else if (
        data.columnName == 'REASON_ID' &&
        data.valuesFrom.length > 0
      ) {
        let reserchString = cloneDeep(data.valuesFrom)
        const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
          const subStringContent = substr.replace('_@', '').replace('@_', '');
          if(this.detail){
            return {
              toReplace: substr,
              raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
            }
          }
          return {
            toReplace: substr,
            raplaceWith: ''
          }
        }) ?? [];

        for(let i=0; i< replaceArray.length; i++){
          reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
        }
        this.generalDataService
          .getSelectionOptionWithFilter(
            reserchString,
            "CATEGORY_ID = '" + this.detail?.CATEGORY_ID + "'"
          )
          .then((res) => {
            data.optionValues = res;
            if(this.detail && data.optionValues.length > 0){
              const reasonId = ((res.find((elem)=> elem.default) ?? (res.length > 0 ? (res[0]) : undefined)))?.id;
              if( !(!!this.detail.REASON_ID)){
                this.detail.REASON_ID = reasonId ?? '';
                if(!!reasonId){
                  this.onColumnChange(reasonId, 'REASON_ID');
                }
              }else{
                this.onColumnChange(reasonId, 'REASON_ID');
              }
              this.detail.REASON_ID
            }
          });
      } else if (
        data.columnName == 'REASON_D_ID' &&
        data.valuesFrom.length > 0
      ) {
        let reserchString = cloneDeep(data.valuesFrom)
        const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
          const subStringContent = substr.replace('_@', '').replace('@_', '');
          if(this.detail){
            return {
              toReplace: substr,
              raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
            }
          }
          return {
            toReplace: substr,
            raplaceWith: ''
          }
        }) ?? [];

        for(let i=0; i< replaceArray.length; i++){
          reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
        }
        this.generalDataService
          .getSelectionOptionWithFilter(
            reserchString,
            "REASON_ID = '" + this.detail?.REASON_ID + "'"
          )
          .then((res) => {
            data.optionValues = res;
            if(this.detail && data.optionValues.length > 0){
              const reasonId = ((res.find((elem)=> elem.default)?? (res.length > 0 ? (res[0]) : undefined)))?.id;
              if( !(!!this.detail.REASON_D_ID) ){
                this.detail.REASON_D_ID = reasonId ?? '';
              }
            }
          });
      } else if (data.valuesFrom.length > 0) {
        let reserchString = cloneDeep(data.valuesFrom)
        const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
          const subStringContent = substr.replace('_@', '').replace('@_', '');
          if(this.detail){
            return {
              toReplace: substr,
              raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
            }
          }
          return {
            toReplace: substr,
            raplaceWith: ''
          }
        }) ?? [];

        for(let i=0; i< replaceArray.length; i++){
          reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
        }
        this.generalDataService
          .getSelectionOption(reserchString)
          .then((res) => {
            data.optionValues = res;
          });
      }
    })

  }

  ngOnInit(): void {

    this.spinner.hideAll();
    //this.isavailable=false

    this.activatedRoute.parent?.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((finalParentUrl) => {
        this.apiParent = finalParentUrl;
        this.refreshDetail()
      });
      this.activatedRoute.queryParams.subscribe(params=>{
       // this.titlePage= params['dmgType'];
        //(this.titlePage === 'WAREHOUSING') ? 'Warehouse' : 'Primary'
        this.titlePage = (params['dmgType'] === 'WAREHOUSING') ? 'Warehouse ' : 'Primary ';
      })



  }

  refreshDetail(){

    this.generalDataService.getDetail(this.apiParent + '/scorecards/problem', this.problemId).subscribe({
      next: value => {
        this.detail = value.oContent
        this.detailBck = cloneDeep(value.oContent)

        console.dir(this.detail)

        setTimeout(() => {
          this.isavailable = true;

        }, 5000);

           // this.isavailable=true;
            this.pncTableController.dataSet = this.detail?.L_SCORECARD_PROBLEM_PNC ?? [];
            this.pncTableController.selection?.clear();

            if ((this.detail?.L_SCORECARD_PROBLEM_PNC ?? []).length > 0) {
              this.pncTableController.row = this.detail?.L_SCORECARD_PROBLEM_PNC[0];
              this.pncTableController.rowNumber = 0;
            }

            if(this.layout){
              this.catchAllSelectedOptions(this.layout);
            }

          },
          error: err => {
            if(this.layout){
              this.catchAllSelectedOptions(this.layout);
            }
          }
        });


  }

  pncActionCatcher(event: string) {
    if (event.startsWith('ACT_ROLLBACK')) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: "Children restoring",
          content: `Would you want to restore all serials?`,
          cancelButtonLabel: "No",
          cancelButtonColor: "basic",
          confirmButtonLabel: "Yes",
          confirmButtonColor: "warn",
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        this.pncTableController.dataSet = this.pncTableController.dataSet.map((pnc, index) => {
          if (
            this.pncTableController.rowNumber == index
          ) {
            delete pnc["deleted"];
            if (res) {
              pnc.L_SCORECARD_PROBLEM_PNC_SERIAL = pnc.L_SCORECARD_PROBLEM_PNC_SERIAL.map(serial => {
                delete serial["deleted"];
              })
            }
          }
          return pnc;
        });
      })
      return;
    }

    if (event.endsWith('_ALL')) {
    } else if (event.endsWith('_ID')) {
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: "Confirm delete selected rows",
            content: `Are you sure to proceed?`,
            cancelButtonLabel: "No",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Yes",
            confirmButtonColor: "warn",
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            this.generalDataService.deleteDetailData(this.apiParent + '/scorecards/problem/pnc', [this.pncTableController.row.SCORECARD_PROBLEM_PNC_ID], new HttpParams().set("problemId", this.pncTableController.row.SCORECARD_PROBLEM_ID)).subscribe(res => {
              this.refreshDetail()
            })
          } else {
            this.pncTableController.deletedRows = [];
          }
        });
      }

      if (event.startsWith('ACT_SAVE')) {
        if (
          !!this.problemId &&
          this.problemId.length > 1 &&
          this.problemId != 'null'
        ) {

          //console.log("PRIMO")
          //console.log(this.detail.CAMERA_ROLL_ID)


          //console.log("secondo")
          //console.log(this.pncTableController.row.CAMERA_ROLL_ID)

          this.spinner.show();
          lastValueFrom(
            this.generalDataService.postData(
              this.apiParent + "/problems/pnc",
              this.pncTableController.dataSet
                .filter((row) => row.edited != undefined && row.edited)
                .map((elem) => {
                  elem["PROBLEM_ID"] = this.problemId;
                  elem["L_SCORECARD_PROBLEM_PNC_SERIAL"] = [];

                  delete elem.checked;
                  return elem;
                }),
              new HttpParams().set("problemId", this.problemId)
            )
          )
            .then((res) => {
              //this.refreshData();
            })
            .catch((err) => {
              this.alertService.add({
                type: ALERT_TYPE.DANGER,
                message: "Unable to save pnc",
                timeout: 8000,
                selfClose: null,
              });
            })
            .finally(() => {
              this.spinner.hide();
              //this.refreshData();
            });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: "Unable to save a new PNC on a problem without a valid id.",
            timeout: 8000,
            selfClose: null,
          });
        }
      }
    } else {
      if (this.pncTableController.selection.selected.length == 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'No rows selected',
            content: `Please select at least 1 row...`,
            confirmButtonLabel: 'Ok',
            confirmButtonColor: 'basic',
          },
        });
        return;
      }

      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: "Confirm delete selected rows",
            content: `Are you sure to proceed?`,
            cancelButtonLabel: "No",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Yes",
            confirmButtonColor: "warn",
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            this.pncTableController.dataSet = this.pncTableController.dataSet.map((pnc, index) => {
              if (
                this.pncTableController.selection.selected.some((pncToDelete) => {
                  return pnc["PROBLEM_ID"] == pncToDelete["PROBLEM_ID"] && pnc["PNC"] == pncToDelete["PNC"];
                })
              ) {
                pnc["deleted"] = true;
                this.pncTableController.deleteErrors(index);
              }
              return pnc;
            });
            this.pncTableController.deletedRows = [];
            this.pncTableController.selection.clear();
          } else {
            this.pncTableController.deletedRows = [];
          }
        });
      }
    }
  }

  onSaveClick(event: any = null, close: boolean = false) {

    this.spinner.show();



    //this.detail.CAMERA_ROLL_ID= this.pncTableController.row.CAMERA_ROLL_ID
    if (this.detail) {
      lastValueFrom(
        this.generalDataService.postData(
          this.apiParent + '/scorecards/problem',
          [this.detail],
          //new HttpParams().set('problemId', this.problemId)
        )
      )
        .then((res) => {

          //console.log(((res['oContent'])[0]))
          //console.log(((res['oContent'])[0])['SCORECARD_PROBLEM_ID'])
          //console.log("quqiiq")
          //console.log(((res['oContent'])[0])['CAMERA_ROLL_ID'])
          this.problemId = ((res['oContent'])[0])['SCORECARD_PROBLEM_ID'] ?? '';
          this.isNew = false;
          const queryParams: Params = { problemId: this.problemId, n: false };

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
        })
        .finally(() => {
          this.spinner.hide();
          if (!close) {
            //this.refreshData();
          } else {
            //this.navigation.back();
            const navItem = this.navigation.findNavItemElemByUrl('page/' + this.apiParent + '/scorecards/detail')
            this.navigation.compareAndGoTo('page/' + this.apiParent + '/scorecards/detail', {
              title: navItem?.displayName ?? '',
              id: navItem?.pageId ?? 'PAG_SCORECARD_DETAIL', // TODO
              t: this.apiParent,
              damage: this.detail.SCORECARD_ID ?? '',
              scr: this.detail.SCORECARD_ID ?? '',
              shipment: this.shipment ?? '',
            }, null, null);
          }
        });
    }
  }

  ngOnDestroy() {
    localStorage.setItem('changes', 'false');
  }

  ngAfterContentChecked() {
    if (!isEqual(this.detailBck, this.detail)) {
      localStorage.setItem('changes', 'true');
      this.isChanged = true;
    } else {
      localStorage.setItem('changes', 'false');
      this.isChanged = false;
    }
  }

  onRowClick(row: number) {
    /*if(this.serialTableController.inError()){
      return;
    }*/
    this.pncTableController.row = this.pncTableController.dataSet[row];
    console.log(this.pncTableController)
    if (this.pncTableController.rowNumber >= 0 && this.serialTableController.dataSet.length > 0) {

      this.pncTableController.dataSet[this.pncTableController.rowNumber].L_SCORECARD_PROBLEM_PNC_SERIAL = this.serialTableController.dataSet.map(serial => {
        delete serial.edited;
        delete serial.editing;
        delete serial.newRow;
        return serial;
      }) ?? [];

      this.recalculateDetail();
    }

    this.serialTableController.dataSet =
      this.pncTableController.dataSet[row]?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? [];


    this.pncTableController.rowNumber = row;

    this.serialTableController.selection.clear();
  }

  onCancelClick() {

    this.dialog.open(BackDialogComponent, {
      data: {
        title: 'Please confirm',
        content: `How do you want to proceed?`
      },
    }).afterClosed().subscribe(res => {
      if(res == 'discard'){
        if (this.isNew && this.detail && this.detail.SCORECARD_PROBLEM_ID){
          lastValueFrom(
            this.generalDataService.deleteData(
              this.apiParent + '/problems',
              [this.detail?.SCORECARD_PROBLEM_ID],
              new HttpParams().set('damageId', (this.detail.SCORECARD_ID ?? ''))
            )
          ).finally(() => {
            //this.navigation.back();

            const navItem = this.navigation.findNavItemElemByUrl('page/' + this.apiParent + '/scorecards/detail')
            this.navigation.compareAndGoTo('page/' + this.apiParent + '/scorecards/detail', {
              title: navItem?.displayName ?? '',
              id: navItem?.pageId ?? 'PAG_SCORECARD_DETAIL', // TODO
              t: this.apiParent,
              damage: this.detail.SCORECARD_ID ?? '',
              shipment: this.shipment,
              scr: this.detail.SCORECARD_ID ?? ''
            }, null, null);
          })
        }else{

          ///page/primary/scorecards/detail?
          if(localStorage.getItem("isNew")==="true"){

            /*const navItem = this.navigation.findNavItemElemByUrl('')
            this.navigation.goTo('', {
              id: navItem?.pageId ?? '',
              title: navItem?.displayName ?? ''
            }, false)*/
            const navItem = this.navigation.findNavItemElemByUrl('page/' + this.apiParent + '/scorecards/detail')
            this.navigation.compareAndGoTo('page/' + this.apiParent + '/scorecards/detail', {
              title: navItem?.displayName ?? '',
              id: navItem?.pageId ?? 'PAG_SCORECARD_DETAIL', // TODO
              t: this.apiParent,
              damage: this.detail.SCORECARD_ID ?? '',
              scr: this.detail.SCORECARD_ID ?? '',
              shipment: this.shipment,
            }, null, null);

          }

          else{
            const navItem = this.navigation.findNavItemElemByUrl('page/' + this.apiParent + '/scorecards/detail')
            this.navigation.compareAndGoTo('page/' + this.apiParent + '/scorecards/detail', {
              title: navItem?.displayName ?? '',
              id: navItem?.pageId ?? 'PAG_SCORECARD_DETAIL', // TODO
              t: this.apiParent,
              damage: this.detail.SCORECARD_ID ?? '',
              scr: this.detail.SCORECARD_ID ?? ''
            }, null, null);
          }



        }
      }else if(res == 'save'){
        this.onSaveClick(res, true);
      }
    });
  }

  openCameraRoll() {

    //this.spinner.show();
  /*lastValueFrom(
      this.generalDataService.getDataById(
        this.apiParent + '/problems/detail/pictures',
        'problem',
        this.problemId
      )
    ).then((pictures) => {

      const dialogRef = this.dialog.open(CameraRollComponent, {
        width: '100%',
        data: {
          title: 'Pictures of ' + this.problemId ,
          content: pictures['oContent'],
          apiUrl: this.apiParent + '/problems/detail/pictures',
          problemId: this.problemId,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'accent',
        },
      });
      //this.spinner.show();
    });
*/

    //console.log(this.p)

    const dialogRef = this.dialog.open(CameraRollV2Component, {

      //height: "100vh",
      //width: "100vw",

      width: "80vw",
      data: {
       
        title: "Attachments",
        files: this.detail.files,

        path: "SCORECARD/"+this.detail.SCORECARD_ID+ '/PROBLEM'+ "/" + this.problemId + "/",
        parent2: this.problemId,
        parentType:"PROBLEM",
        parent: this.detail?.SCORECARD_ID,
        isViewOnly: false,
        cancelButtonLabel: "Close",
        cancelButtonColor: "accent",
        
      },
    });

dialogRef.afterClosed().subscribe(res => {
  this.refreshDetail()
})

  }

  onColumnChange(event: string, columnName: string) {

    if (columnName == 'CATEGORY_ID') {
      this.onSaveClick()
      /*if (this.detail) {
        this.detail.REASON_ID = '';
        this.detail.REASON_D_ID = '';
      }*/
      if(this.pageData.get('REASON_ID')){
        let data = cloneDeep(this.pageData.get('REASON_ID') as Data)
        if(!!this.detail){
          let reserchString = cloneDeep(data.valuesFrom)
          const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
            const subStringContent = substr.replace('_@', '').replace('@_', '');
            if(this.detail){
              return {
                toReplace: substr,
                raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
              }
            }
            return {
              toReplace: substr,
              raplaceWith: ''
            }
          }) ?? [];

          for(let i=0; i< replaceArray.length; i++){
            reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
          }
          this.generalDataService
            .getSelectionOptionWithFilter(
              reserchString,
              "CATEGORY_ID = '" + this.detail[columnName] + "'"
            )
            .then((res) => {
              data.optionValues = res;
              this.pageData.set('REASON_ID', data)
              if (this.detail && data.optionValues.length > 0) {
                if(!(!!this.detail.REASON_ID && res.some(elem => elem.id==this.detail.REASON_ID))){
                  const reasonId = ((res.find((elem) => elem.default) ?? (res.length > 0 ? (res[0]) : undefined)))?.id;
                  if (this.detail.REASON_ID != reasonId) {
                    this.detail.REASON_ID = reasonId ?? "";
                    this.onSaveClick()
                    if(this.detail.REASON_ID)
                      this.onColumnChange(this.detail.REASON_ID, 'REASON_ID');
                  }
                }

              }
            });
        }

      }
    } else if (columnName == 'REASON_ID') {
      /*if (this.detail) {
        this.detail.REASON_D_ID = '';
      }*/
      this.onSaveClick();
      if(!!this.pageData.get('REASON_D_ID') && !!this.detail){

        let data = (this.pageData.get('REASON_D_ID') as Data)


        let reserchString = cloneDeep(data.valuesFrom)
        const replaceArray = data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map(substr => {
          const subStringContent = substr.replace('_@', '').replace('@_', '');
          if(this.detail){
            return {
              toReplace: substr,
              raplaceWith: convertFormat(this.detail[subStringContent], subStringContent) ?? ''
            }
          }
          return {
            toReplace: substr,
            raplaceWith: ''
          }
        }) ?? [];

        for(let i=0; i< replaceArray.length; i++){
          reserchString = reserchString.replace((replaceArray[i]).toReplace, '\''+(replaceArray[i]).raplaceWith.replace(/\//gm, '-')+'\'')
        }

        this.onSaveClick()

        this.generalDataService
          .getSelectionOptionWithFilter(reserchString, "REASON_ID = '" + this.detail[columnName] + "'")
          .then((res) => {
            data.optionValues = res;
            this.pageData.set('REASON_D_ID', data)
            if (this.detail && data.optionValues.length > 0) {
              if(!(!!this.detail.REASON_D_ID && res.some(elem => elem.id==this.detail.REASON_D_ID))){
                const reasonDId = ((res.find((elem) => elem.default) ?? (res.length > 0 ? (res[0]) : undefined)))?.id;
                if (this.detail.REASON_D_ID != reasonDId) {
                  this.detail.REASON_D_ID = reasonDId ?? "";
                  this.onSaveClick()
                  if (!!reasonDId && this.layout) {
                    //this.catchAllSelectedOptions(this.layout);
                  }
                }
              }

            }
          });


      }
    }else if (columnName == 'REASON_D_ID') {
      this.onSaveClick()
    }
  }

  onCloseClick() {

    if (this.pncTableController.rowNumber >= 0 && this.pncTableController.dataSet.length > 0) {
      this.pncTableController.dataSet[this.pncTableController.rowNumber].L_SCORECARD_PROBLEM_PNC_SERIAL = this.serialTableController.dataSet.map(serial => {
        delete serial.edited;
        delete serial.editing;
        delete serial.newRow;
        return serial;
      }) ?? []/*.filter(serial => !serial.deleted)*/;
    }
    this.detail ? this.detail.L_SCORECARD_PROBLEM_PNC = this.pncTableController.dataSet.map(pnc => {
      delete pnc.edited;
      delete pnc.newRow;
      delete pnc.editing;
      return pnc;
    })/*.filter(pnc => !pnc.deleted)*/ : []

    if (this.detail) {
      if (this.detail.CATEGORY_ID == 'FA') {
        this.detail.L_SCORECARD_PROBLEM_PNC.forEach(pnc => {
          pnc.PNC_APPLIANCES_QTY = pnc.L_SCORECARD_PROBLEM_PNC_SERIAL.reduce((acc, serial) => {
            return serial.IS_APPLIANCE ? acc + 1 : acc
          }, 0)

          pnc.PNC_PACKAGES_QTY = pnc.L_SCORECARD_PROBLEM_PNC_SERIAL.reduce((acc, serial) => {
            return serial.IS_PACKAGE ? acc + 1 : acc
          }, 0)
        })
      }
      this.detail.PROBLEM_APPLIANCES_QTY
        = this.detail.L_SCORECARD_PROBLEM_PNC?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_APPLIANCES_QTY) ?? 0)
        }, 0) ?? 0;
      this.detail.PROBLEM_PACKAGES_QTY
        = this.detail.L_SCORECARD_PROBLEM_PNC?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_PACKAGES_QTY) ?? 0)
        }, 0) ?? 0;
    }


    this.dialogRef.close({ data: this.detail })
  }

  recalculateDetail() {
    if (this.detail) {
      if (this.detail.CATEGORY_ID == 'FA' && this.pncTableController.rowNumber >= 0 && this.pncTableController.dataSet.length > 0) {
        this.pncTableController.dataSet = this.pncTableController.dataSet.map(pnc => {
          pnc.PNC_APPLIANCES_QTY = pnc.L_SCORECARD_PROBLEM_PNC_SERIAL?.reduce((acc, serial) => {
            return !!serial.IS_APPLIANCE ? acc + 1 : acc
          }, 0)

          pnc.PNC_PACKAGES_QTY = pnc.L_SCORECARD_PROBLEM_PNC_SERIAL?.reduce((acc, serial) => {
            return !!serial.IS_PACKAGE ? acc + 1 : acc
          }, 0)

          return pnc;
        })
      }
      this.detail.PROBLEM_APPLIANCES_QTY
        = this.pncTableController.dataSet?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_APPLIANCES_QTY) ?? 0)
        }, 0) ?? 0;
      this.detail.PROBLEM_PACKAGES_QTY
        = this.pncTableController.dataSet?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_PACKAGES_QTY) ?? 0)
        }, 0) ?? 0;
    }
  }

  onPncClick(action: String) {

    console.log(action)

    let n, pnc, app, pack, scorecardProblemPncId, problemId, scorecardId, category,cameraRollId;
    let pncSerial = {
      pnc: '',
      serial: ''
    }

    if (action == 'ACT_ADD_PNC' || action == 'ACT_ADD_PNC_WH') {


      //if (this.detail?.CATEGORY_ID == 'FA' || !this.apiParent.toLowerCase().includes('primary') ) {
       // if (this.detail?.CATEGORY_ID == 'FA' || !this.apiParent.toLowerCase().includes('primary') ) {

        this.qrCodeScanner().subscribe(closeResult => {
          if (!!closeResult) {

            const newSerial: PncSerial = {
              IS_APPLIANCE: !closeResult.isPkg,
              IS_PACKAGE: closeResult.isPkg,
              SCORECARD_PROBLEM_PNC_ID: '',
              SCORECARD_PROBLEM_PNC_SERIAL_ID: '',
              SERIAL_NUMBER: closeResult.serial
            }

            this.generalDataService.postSerialDetail(this.apiParent + '/scorecards/problem/pnc/serial', newSerial,  this.problemId, closeResult.pnc).subscribe({
              next: value => {
                this.refreshDetail();
                if(value['iResponseCode'] == 200 && !(!!closeResult.stop)){
                  this.onPncClick(action);
                }else{

                  this.alertService.add({
                    type:  ALERT_TYPE.SUCCESS,
                    message:this.translateService.instant('LABEL_SAVED'),
                    timeout: 5000,
                    selfClose: null
                  });
                }

              },
              error: err => {
                //alert(this.translateService.instant('LABEL_ERROR_SERIAL'))
                this.alertService.add({
                  type:  ALERT_TYPE.DANGER,
                  message:this.translateService.instant('LABEL_ERROR_SERIAL'),
                  timeout: 5000,
                  selfClose: null
                });

              }
            });
          }
        });

      /*}else{
        console.log("elelele")
        this.router.navigate(
          ['page/' + this.apiParent + '/scorecards/problem/pnc'],
          {
            //state: result,
            queryParams: {
              id:
                this.navigation.changeQueryParmas(
                  'page/' + this.apiParent + '/scorecards/problem/pnc'
                ) ?? '',
              n: true,
              pnc: "",
              app: 0,
              pack: 0,
              scorecardProblemPncId: "",
              problemId: this.detail?.SCORECARD_PROBLEM_ID,
              scorecardId: this.detail?.SCORECARD_ID,
              category: this.detail?.CATEGORY_ID,
            },
          }
        );
      }*/

      /*n = true;
      pnc = '';
      app = 0;
      pack = 0;
      scorecardProblemPncId = '';
      problemId = '';
      scorecardId = this.detail?.SCORECARD_ID;
      category = this.detail?.CATEGORY_ID;*/


    } else if (action == 'ACT_EDIT_PNC') {

      this.router.navigate(
        ['page/' + this.apiParent + '/scorecards/problem/pnc'],
        {
          //state: result,
          queryParams: {
            id:
              this.navigation.changeQueryParmas(
                'page/' + this.apiParent + '/scorecards/problem/pnc'
              ) ?? '',
            n: false,
            pnc: this.pncTableController.row?.PNC,
            app: this.pncTableController.row?.PNC_APPLIANCES_QTY ?? 0,
            pack: this.pncTableController.row?.PNC_PACKAGES_QTY ?? 0,
            scorecardProblemPncId: this.pncTableController.row?.SCORECARD_PROBLEM_PNC_ID,
            problemId: this.detail?.SCORECARD_PROBLEM_ID,
            scorecardId: this.detail?.SCORECARD_ID,
            category: this.detail?.CATEGORY_ID,
            cameraRollId:this.detail?.CAMERA_ROLL_ID,

          },
        }
      );

    } /*else if (action == 'ACT_ADD_PNC_WH') {
      console.log(this.detail)
      this.router.navigate(
        ['page/' + this.apiParent + '/scorecards/problem/pnc'],
        {
          //state: result,
          queryParams: {
            id:
              this.navigation.changeQueryParmas(
                'page/' + this.apiParent + '/scorecards/problem/pnc'
              ) ?? '',
            n: true,
            pnc: "",
            app: 0,
            pack: 0,
            scorecardProblemPncId: "",
            problemId: this.detail?.SCORECARD_PROBLEM_ID,
            scorecardId: this.detail?.SCORECARD_ID,
            category: this.detail?.CATEGORY_ID,
          },
        }
      );
    }*/
  }

  qrCodeScanner() : any {
    const qrCodeDialog = this.dialog.open(SerialSelectionComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh'
    })

    return qrCodeDialog.afterClosed();
  }
}

